.index-page .squares {
  @include linear-gradient($danger-states, $danger);
}
.register-page,
.section.section-signup {
  .squares,
  .square {
    background: linear-gradient(rgba(50, 50, 93, 0.11), #009adc6b);
  }
}
.footer {
  border-top: 2px solid rgb(240, 150, 17);
}
