/* You can add global styles to this file, and also import other style files */
@import "~bootstrap-icons/font/bootstrap-icons.css";


.oferta:hover {
    transform: scale(2.3);
    z-index: 1;
    
    background-color: #5500f2;
    animation-duration: 3s;
    animation-name: slidein;
}
.oferta1:hover {
    transform: scale(2.3);
    z-index: 1;
    
    background-color: #00f2c3;
    animation-duration: 3s;
    animation-name: slidein;
}
.oferta2:hover {
    transform: scale(2.3);
    z-index: 1;
   
    background-color: #f2b100;
    animation-duration: 3s;
    animation-name: slidein;
}
.oferta3:hover {
    transform: scale(2.3);
    z-index: 1;
    
    background-color: #f20049;
    animation-duration: 3s;
    animation-name: slidein;
}

@keyframes slidein {
    from {
      margin-left: 100%;
      width: 300%
    }
  
    to {
      margin-left: 0%;
      width: 100%;
    }
  }